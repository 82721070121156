import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'; 
import {apiLogin} from '../../services/api/accounts/Login';

const LoginComponent = () => {
    const { register, handleSubmit, reset } = useForm();
    const [validationMessage, setValidationMessage] = useState('');
    const [validationGreenMessage, setValidationGreenMessage] = useState('');

    const onSubmit = async (data) => {
        let result = await apiLogin.login(data);
        if (result.error) {
            setValidationMessage(result.error);
        }
        else {
            localStorage.setItem('token', result.token);
            localStorage.setItem('fullName', result.fullName);
            localStorage.setItem('role', result.role);
            localStorage.setItem('email', result.name);
            localStorage.setItem('avatar', result.avatar);
            window.location.href = "/index";
        }
    };

    let location = useLocation();

    useEffect(() => {

        if (location.search.indexOf('type=1') >= 0) {
            setValidationGreenMessage('Link do przypomnienia hasła został wysłany, sprawdź maila aby kontynować przypomnienie hasła');
        }
        else if (location.search.indexOf('type=2') >= 0) {
            setValidationGreenMessage('Link do kontynuacji rejestracji został wysłany, sprawdź maila aby kontynować rejestracje konta');
        }
        else if (location.search.indexOf('type=3') >= 0) {
            setValidationGreenMessage('Gratulacje!, konto zostało zarejestrowane');
        }
    }, []);

    return (
        <Grid container className="center-vertical-item">
            <Container component="main" maxWidth="sm" className="login-container height100">
                <a className="logo-small"></a>
                <Grid container direction="column" className="height100">
                    <Grid className="login-first-row">
                        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h1>Zaloguj się</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="validation-error">{validationMessage}</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="validation-green-error">{validationGreenMessage}</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>Email</FormLabel>
                                    <TextField required autoFocus {...register("login", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>Hasło</FormLabel>
                                    <TextField required type="password" name="password" {...register("password", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <Link to="/forgot" className="blue-font">Nie pamiętasz hasła?</Link>
                                </Grid>
                                <br/>
                                <Grid item xs={12}>
                                    <Button type="submit" className="button" fullWidth color="primary">Zaloguj</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid className="login-options">
                        <div className="space"></div>
                        <div className="bottom-line">
                            <Link to="/registration">Nie masz konta?&nbsp;<span>Zarejestruj się</span></Link>
                        </div>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid className="bottom-options">
                    <Link>Dowiedz się więcej o bezpieczeństwie</Link>
                    <Link>Regulamin</Link>
                    <Link>Polityka prywatności</Link>
                </Grid>
            </Container>
        </Grid>
    );
}

export default LoginComponent;