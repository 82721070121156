import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import { useForm } from "react-hook-form";
import {apiLogin} from '../../services/api/accounts/Login';
import { Link } from 'react-router-dom'; 
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';

const RegistrationComponent = () => {
    const { register, handleSubmit, reset } = useForm();
    const [validationMessage, setValidationMessage] = useState('');

    const onSubmit = async (data) => {
        let result = await apiLogin.registration(data);
        if (result.error) {
            setValidationMessage(result.error);
        }
        else {
            window.location.href = "/login?type=2";
        }
    };

    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
    }, [reset]);

    return (
        <Grid container className="center-vertical-item">
            <AppBar position="static">
                <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }} onClick={()=>navigate(-1)}
                >
                    <ArrowBackIosIcon/>
                </IconButton>
                    <h6>
                        Rejestracja
                    </h6>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="sm" className="login-container height100">
                <Grid container direction="column" className="height100">
                    <Grid className="login-first-row">
                        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h1>Zarejestruj się</h1>
                                    <h2>Na twój adres e-mail zostanie wysłany link do aktywacji konta</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="validation-error">{validationMessage}</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>Email</FormLabel>
                                    <TextField required autoFocus {...register("email", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>Imię</FormLabel>
                                    <TextField required {...register("name", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>Nazwisko</FormLabel>
                                    <TextField required {...register("surname", { required: true })} /><br />
                                </Grid>
                                <br/>
                                <Grid item xs={12}>
                                    <Button type="submit" className="button" fullWidth color="primary">Zarejstruj się</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid className="login-options">
                        <div className="space"></div>
                        <div className="bottom-line">
                            <Link to="/registration">Nie masz konta?&nbsp;<span>Zarejestruj się</span></Link>
                        </div>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid className="bottom-options">
                    <Link>Dowiedz się więcej o bezpieczeństwie</Link>
                    <Link>Regulamin</Link>
                    <Link>Polityka prywatności</Link>
                </Grid>
            </Container>
        </Grid>
    );
}

export default RegistrationComponent;