import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useLocation, useNavigate,useSearchParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import {apiDocument} from '../../services/api/documents/Document';
import { apiProvider } from '../../services/api/utilities/Provider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {PullToRefresh,PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";
import SignatureCanvas from 'react-signature-canvas'

const SignComponent = () => {
    const { register, handleSubmit, reset } = useForm();
    const [validationMessage, setValidationMessage] = useState('');
    const [searchParams] = useSearchParams();
    const [fileName, setFileName] = useState('');
    const [model, setModel] = useState({});
    const [items, setItems] = useState([]);
    const [myItems, setMyItems] = useState([]);
    const [theirItems, setTheirItems] = useState([]);
    const [isSign, setIsSign] = useState(false);
    const [sigCanvas,setSigCanvas] = useState({});
    const [isMenu, setIsMenu] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const refreshDocument = async(id) => {
        if (location.search.indexOf('id=') >= 0) {
            let document = await apiDocument.get(searchParams.get('id'));
            if(document!==null)
            {
                setModel({id:document.id, name: document.name,sendUserProfileName:document.sendUserProfileName, sendDateName: document.sendDateName, summaryURL: document.summaryURL, isToSigned: document.isToSigned, isMySigned: document.isMySigned, isMy: document.isMy, status: document.status, signQuantity: document.signQuantity+'/'+document.items.length, fileURL:document.fileURL});
                if(document.items!==null)
                {
                    setMyItems(document.items.filter(d=>!d.isTheir));
                    setTheirItems(document.items.filter(d=>d.isTheir));
                    var index = 0;
                    items.length=0;
                    (document.signItems).forEach(element => {
                        if(!element.item.isSigned)
                        {
                            items.push({email: element.item.email, firstName: element.item.firstName, lastName: element.item.lastName, companyName: element.item.companyName, companyNumber: element.item.companyNumber, y:element.y, x:element.x, index: index + (element.item.isTheir ? 4:0), page: element.page});
                        }
                        index++;
                    });
                    setItems([...items]);
                    setFileName(document.fileURL);
                }
            }
        }
    }

    const showDocument = async()=> {
        window.open(`${apiProvider.BASE_URL}${fileName}`);
    }

    const sign = async () => {
        let result = await apiDocument.sign({ id: model.id, signature: sigCanvas.toDataURL() });
        if(result!=null)
        {
            window.location.href = "/index";
        }
    }

    const downloadFile = async()=>{
        window.open(`${apiProvider.BASE_URL}${fileName}`);
    }

    const downloadSummaryFile = async()=>{
        window.open(`${apiProvider.BASE_URL}${model.summaryURL}`);
    }

    const setSign = async()=>{
        setIsSign(true);
    }

    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            await refreshDocument();
        }
        fetchData();
    }, []);

    return (
        <div className="main-body">
            <AppBar position="static">
                <Toolbar>
                    <Grid container>
                        <Grid item style={{width:"35px", marginTop: "35px"}}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }} onClick={()=>navigate(-1)}
                            >
                                <ArrowBackIosIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item xs>
                            <h6>
                                Dokument
                            </h6>
                        </Grid>
                        <Grid item style={{width:"35px", marginTop: "35px"}}>
                            <IconButton onClick={()=>setIsMenu(true)}
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                <MoreVertIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {isMenu && 
            <div className="mobile-menu">
                <ul container>
                    <li>
                        <Grid container>
                            <Grid item xs>
                                Opcje
                            </Grid>
                            <Grid item style={{width:"15px",marginTop:"-15px"}}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }} onClick={()=>setIsMenu(false)}
                                >
                                    <ExpandLessIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </li>
                    <li onClick={()=>downloadSummaryFile()}>
                        <i className='show-gray'></i>Podgląd metryczki
                    </li>
                    <li onClick={()=>downloadSummaryFile()}>
                        <i className='download-gray'></i>Pobierz metryczkę
                    </li>
                </ul>
            </div>
            }
            <div style={{position:'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} className={`menu-bottom-2 ${ isMenu ? "blur":''}`}>
                {!isSign && <div>
                {model!==null && (model.isToSigned===0 || model.isMySigned) && 
                    <Button className='button button-white' onClick={()=>showDocument()}><i className='show'></i>&nbsp;&nbsp;Podgląd</Button>    
                }
                {model!==null && (model.isToSigned>0 && !model.isMySigned) && 
                    <Button className='button button-white' onClick={()=>setSign()}><i className='pencil pencil-sign'></i>&nbsp;&nbsp;Podpisz</Button>
                }
                <Button className='button' onClick={()=>downloadFile()}><i className='download'></i>&nbsp;&nbsp;Pobierz</Button>
                </div>}
                {isSign && <div>
                    <Button className='button button-white' onClick={()=>sign()}><i className='pencil pencil-sign'></i>&nbsp;&nbsp;Zatwierdź</Button>
                    <Button className='button' onClick={()=>downloadFile()}><i className='download'></i>&nbsp;&nbsp;Pobierz</Button>
                </div>}
            </div>
            {!isSign && 
            <div style={{marginTop:5}} className={`${ isMenu ? "blur":''}`}>
                <PullToRefresh
                    pullDownContent={<PullDownContent />}
                    releaseContent={<ReleaseContent />}
                    refreshContent={<RefreshContent />}
                    pullDownThreshold={200}
                    onRefresh={()=>refreshDocument()}
                    triggerHeight={50}
                    backgroundColor='white'
                    startInvisible={true}>
                        <div className="wrapper">
                            <Grid container>
                                <Grid item xs={12} className="description">
                                    <p>
                                        {(model.status===0 || model.status===1) && <i className="red-dot"></i>}
                                        {model.status===2 && <i className="yellow-dot"></i>}
                                        {model.status===3 && <i className="green-dot"></i>}&nbsp;&nbsp;{model.name}
                                    </p>
                                </Grid>
                                <Grid item xs={12} className="description-small">
                                    <p><span>Data wysłania: </span>{model.sendDateName}&nbsp;</p>
                                </Grid>
                                <Grid item xs={12} className="description-small">
                                    <p><span>Wysłane przez: </span>{model.sendUserProfileName}</p>
                                </Grid>
                                <Grid item xs={12} className="description-small">
                                    <p><span>Podpisy: </span>{model.signQuantity}</p>
                                </Grid>
                                <Grid item xs={12} className="description-small">
                                    {model!==undefined && model.signOrder===1 ? 'My':(model!==undefined && model.signOrder===2 ? 'Wszyscy razem':'Partner')}
                                </Grid>
                                <Grid item xs={12}>
                                    <h5>Podpisy po naszej stronie</h5>
                                </Grid>
                                <Grid item xs={12} className="description-small">
                                    <p><span>Kolejność podpisywania: </span>{model.signMyOrder ? 'Według kolejności na liście':'Wszyscy razem'}</p>
                                </Grid>
                                {myItems.map((row, index) => {
                                    return(
                                        <Grid item xs={12} className="sign-box">
                                            <Grid container>
                                                <Grid xs className="description-small">
                                                    <p>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}</p>
                                                    <p>{row.email}</p>
                                                </Grid>
                                                <Grid className="sign-quantity">
                                                    ({items.filter(d=>d.email==row.email).length})
                                                </Grid>
                                            </Grid>
                                        </Grid>)})
                                }
                                
                                <Grid item xs={12}>
                                    <h5>Podpisy po stronie partnera</h5>
                                </Grid>
                                <Grid item xs={12} className="description-small">
                                    <p><span>Kolejność podpisywania: </span>{model.signTheirOrder ? 'Według kolejności na liście':'Wszyscy razem'}</p>
                                </Grid>
                                {theirItems.map((row, index) => {
                                    return(
                                        <Grid item xs={12} className="sign-box yellow-background">
                                            <Grid container>
                                                <Grid xs className="description-small">
                                                    <p>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}</p>
                                                    <p>{row.email}</p>
                                                </Grid>
                                                <Grid className="sign-quantity">
                                                    ({items.filter(d=>d.email==row.email).length})
                                                </Grid>
                                            </Grid>
                                        </Grid>)})
                                }
                            </Grid>
                        </div>
                </PullToRefresh>
            </div>
            }
            {isSign && 
                <div>
                    <SignatureCanvas canvasProps={{width: 400, height: 800, className: 'sigCanvas'}} ref={(ref) => { setSigCanvas(ref) }}/>
                </div>
            }
        </div>
    );
}

export default SignComponent;