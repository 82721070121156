import React, { useState, useEffect } from 'react';
import { Button,Grid,  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Checkbox, TextField } from '@material-ui/core';
import { useForm } from "react-hook-form";
import {apiDocument} from '../../services/api/documents/Document';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {PullToRefresh,PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";
import {apiLogin} from '../../services/api/accounts/Login';

const IndexComponent = () => {
    const [locale, setLocale] = useState('pl-PL');
    const { register, handleSubmit, reset } = useForm();
    const [items, setItems] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [fullName, setFullName] = useState([]);
    const [isAll, setIsAll] = useState(false);
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(0);
    const [queryParams, setQueryParams] = useState({ start: 0, end: 10, search: '',isWaitForMe:true });
    const [isMenu, setIsMenu] = useState(false);
    const [isNotification, setIsNotification] = useState(false);

    const logOff = () =>{
        localStorage.setItem('token', '');
        localStorage.setItem('fullName', '');
        localStorage.setItem('role', '');
        localStorage.setItem('email', '');
        localStorage.setItem('avatar', '');
        window.location.href = "/";
    }

    useEffect(() => {
        setFullName(localStorage.getItem('fullName'));
        async function fetchData() {
            await refreshItems();
        }

        fetchData();
    }, []);

    const refreshItems = async ()=>{
        let data = await apiDocument.search(queryParams);
        if (data != null) {
            for(var i=0;i<data.items.length;i++)
            {
                data.items[i].isChecked=false;
            }
            setItems(data.items);
            setLength(data.length);
        }

        let notifications = await apiLogin.getNotificationUser();
        setNotifications(notifications.items);
    }

    const all = async() => {        
        setPage(0);
        queryParams.search = '';
        queryParams.isWaitForMe = false;
        setIsAll(true);
        setQueryParams({ start: 0, end: 10, search: '',isWaitForMe:false });
        await refreshItems();
    }

    const my = async() => {        
        setPage(0);
        queryParams.isWaitForMe = true;
        queryParams.search = '';
        setQueryParams({ start: 0, end: 10, search: '',isWaitForMe:true });
        setIsAll(false);
        await refreshItems();
    }
    
    const search = async(query) => {        
        setPage(0);
        queryParams.search = query;
        setQueryParams({ start: 0, end: 10, search: query,isWaitForMe:isAll,isWaitForMe:!isAll });
        await refreshItems();
    }

    const clickDocument = async(id) => {
        window.location.href = "/sign?id="+id;
    }

    const readNotification = async (id, documentId) =>{
        let data = await apiLogin.readNotifcation(id);
        window.location.href = "/sign?id="+documentId;
    }

    return (
        <div className="main-body">
            <AppBar position="static">
                <Toolbar>
                    <Grid container>
                        <Grid item xs>
                            <h6>
                                {fullName}
                            </h6>
                        </Grid>
                        <Grid item style={{width:"35px", marginTop: "35px"}}>
                            <IconButton
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }} onClick={()=>setIsNotification(true)}
                            >
                                <CircleNotificationsIcon/>
                             </IconButton>
                        </Grid>
                        <Grid item style={{width:"35px", marginTop: "35px"}}>
                            <IconButton
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }} onClick={()=>setIsMenu(true)}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {isNotification && 
                <div className="mobile-menu" onClick={()=>setIsNotification(false)}>
                    <ul container>
                        <li>
                            <Grid container>
                                <Grid item xs>
                                    Powiadomienia
                                </Grid>
                                <Grid item style={{width:"15px",marginTop:"-15px"}}>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                    >
                                        <ExpandLessIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </li>
                        {notifications.map((row, index) => {
                            return(<li key={index}><a onClick={()=>readNotification(row.id,row.documentId)}>{row.description}</a></li>)
                        })}
                    </ul>
                </div>
            }
            {isMenu && 
            <div className="mobile-menu" onClick={()=>setIsMenu(false)}>
                <ul container>
                    <li>
                        <Grid container>
                            <Grid item xs>
                                Opcje
                            </Grid>
                            <Grid item style={{width:"15px",marginTop:"-15px"}}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >
                                    <ExpandLessIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </li>
                    <li>Regulamin</li>
                    <li>Polityka prywatności</li>
                    <li onClick={()=>{logOff()}}>
                        Wyloguj
                    </li>
                </ul>
            </div>
            }
            <Paper sx={{position:'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} className={`menu-bottom ${ isMenu ? "blur":''}`}>
                <BottomNavigation>
                    <BottomNavigationAction onClick={()=>my()} label="Recents" icon={<p><i className="pencil"></i><a>Do podpisania</a></p>} className={!isAll ? "selected":""}/>
                    <BottomNavigationAction onClick={()=>all()} label="Favorites" icon={<p><i className="documents"></i><a>Wszystkie dokumenty</a></p>} className={isAll ? "selected":""}/>
                </BottomNavigation>
            </Paper>
            <PullToRefresh
                pullDownContent={<PullDownContent />}
                releaseContent={<ReleaseContent />}
                refreshContent={<RefreshContent />}
                pullDownThreshold={200}
                onRefresh={()=>refreshItems()}
                triggerHeight={50}
                backgroundColor='white'
                startInvisible={true}
                >
            <div className={`wrapper ${ isMenu ? "blur":''}`}>
                <Grid container>
                    <Grid item xs={12}><h4>{isAll ? "Lista dokumentów":"Do podpisania"}</h4></Grid>
                </Grid>
                <Grid container>
                    <Grid item className="search-field" xs={12}>
                        <i className="search"></i>
                        <TextField placeholder='Wpisz słowo kluczowe' className='search-input' {...register("search",{onChange: (e) => search(e.target.value)})}/>
                    </Grid>
                </Grid>

                <Grid container>
                    {items.map((row, index) => {
                        return(
                            <Grid item className={"document-box"+" "+((!row.isToSigned || row.isMySigned) ? "no-background":"")} xs={12} key={index} onClick={()=>clickDocument(row.id)}>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <Grid container>
                                            <Grid item>
                                                {row.documentType===1 && <i className='arrow-up'></i>}
                                                {row.documentType!==1 && <i className='arrow-down'></i>}
                                            </Grid>
                                            {(row.isToSigned!==0 && !row.isMySigned) && 
                                                <Grid item>
                                                   <i className='edit'></i>
                                                </Grid>
                                            }
                                            {(row.isToSigned===0 || row.isMySigned) && 
                                                <Grid item>
                                                   <i className='document'></i>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p>{(row.status===0 || row.status===1) && <i className="red-dot"></i>}
                                            {row.status===2 && <i className="yellow-dot"></i>}
                                            {row.status===3 && <i className="green-dot"></i>}&nbsp;&nbsp;{row.name}</p>
                                        <p><span>Data wysłania: </span>{row.sendDateName}&nbsp; 
                                            {row.isFull && <i className='clock-row'></i>}
                                            {row.isSemi && !row.isFull && <i className='semi-clock-row'></i>}
                                            {row.isQuarter && !row.isFull && !row.isSemi && <i className='quarter-clock-row'></i>}
                                            {row.isEmpty && !row.isQuarter && !row.isFull && !row.isSemi && <i className='empty-clock-row'></i>}
                                            {!row.isEmpty && !row.isQuarter && !row.isFull && !row.isSemi && <i className='empty-clock-row'></i>}</p>
                                        <p><span>Wysłane przez: </span>{row.sendUserProfileName}</p>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            sx={{ mr: 2, mt:0.5 }}>
                                            <ArrowForwardIosIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
            </PullToRefresh>
        </div>
    );
}

export default IndexComponent;