import React from 'react'
import './App.css';
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Login from './components/login/LoginComponent';
import Registration from './components/registration/RegistrationComponent';
import Forgot from './components/forgot/ForgotComponent';
import Index from './components/document/IndexComponent';
import Sign from './components/sign/SignComponent';
import { Link } from 'react-router-dom'; 
import { AppBar, Toolbar, Popper, MenuList, MenuItem, ClickAwayListener, Grow, Paper, Grid  } from '@material-ui/core';

function App() {
  const [open, setOpen] = React.useState(false);
  const [isMenu, setIsMenu] = React.useState(false);
  const [isOpenUser, setIsOpenUser] = React.useState(false);
  const [isOpenContacts, setIsOpenContacts] = React.useState(false);
  const anchorRef = React.useRef(null);

  const logOff = () =>{
      localStorage.setItem('token', '');
      localStorage.setItem('fullName', '');
      localStorage.setItem('role', '');
      localStorage.setItem('email', '');
      localStorage.setItem('avatar', '');
      window.location.href = "/";
  }

  const isAuthenticated = localStorage.getItem('token') != null && localStorage.getItem('token') != '';
  const role = localStorage.getItem('role');
  const email = localStorage.getItem('email');
  const fullName = localStorage.getItem('fullName');
  const avatar = localStorage.getItem('avatar') != null ? localStorage.getItem('avatar') : null;

  return (
    <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="login" element={<Login />}></Route>
            <Route path="registration" element={<Registration />} />
            <Route path="forgot" element={<Forgot />} />
            <Route path="sign" element={<Sign />} />
            <Route path="index" element={<Index />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
